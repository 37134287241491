import { Input, Modal } from 'antd';
import { useState } from 'react';
import { sendRequest } from '../../lib/api';
import './styles.css';

interface Props {
  visible: boolean;
  onClose: () => void;
}

const InviteUserModal = ({ visible, onClose }: Props) => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(false);

  const confirmClick = async () => {
    if (disabled) return;
    if (!email) {
      window.alert('Please enter an email');
      return;
    }
    setDisabled(true);
    const res = await sendRequest({ email }, 'POST', 'api/invite-user');
    if (res.status) {
      window.alert('Invitation sent successfully');
      onClose();
    } else {
      setDisabled(false);
      window.alert(res.data?.message ?? 'Error');
    }
  };

  return (
    <Modal
      title='Invite User'
      onOk={confirmClick}
      onCancel={onClose}
      open={visible}
    >
      <div className='wrap'>
        <div style={{ marginBottom: '20px', marginTop: '30px' }}>
          <label>Invite email</label>
          <Input
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
