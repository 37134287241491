import { useEffect, useState } from 'react';
import HeaderWrapper from '../../components/Header/HeaderWrapper';
import Navbar from '../../components/Header/Navbar';
import Logo from '../../components/Header/Logo';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormError from '../../components/SignForm/SignFormError';
import SignFormWInput from '../../components/SignForm/SignFormInput';
import SignFormButton from '../../components/SignForm/SignFormButton';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../lib/api';

const ResetPasswordPage = () => {
  const history = useHistory();
  const params = useParams<{ token: string }>();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');

    const res = await sendRequest(
      { token: params.token, password },
      'POST',
      'api/auth/reset-password',
    );
    if (res.status) {
      window.alert('Your password has been reset successfully!');
      history.replace('/signin');
    } else {
      setError(res.data?.message ?? 'Unknown error');
    }
  };

  useEffect(() => {
    sendRequest({ token: params.token }, 'POST', 'api/auth/check-token').then(
      res => {
        if (res.status) {
          setDisabled(false);
        } else {
          setDisabled(true);
          setError(res.data?.message ?? 'Error');
        }
      },
    );
  }, [params.token]);

  return (
    <>
      <HeaderWrapper>
        <Navbar hideNav={true}>
          <Logo />
        </Navbar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>
              Boomer Sooner! Enter yOUr new password:
            </SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormWInput
              type='password'
              placeholder='New password:'
              autoComplete='off'
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <SignFormButton disabled={disabled} type='submit'>
              Reset My Password
            </SignFormButton>
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
};

export default ResetPasswordPage;
