import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormInput from '../../components/SignForm/SignFormInput';
import SignFormButton from '../../components/SignForm/SignFormButton';
import SignFormText from '../../components/SignForm/SignFormText';
import SignFormLink from '../../components/SignForm/SignFormLink';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { authRegister } from '../../store/auth/auth.actions';
import ImgCrop from 'antd-img-crop';
import { Checkbox, Collapse, Input, Select, Upload } from 'antd';
import CameraIcon from '../../components/icons/CameraIcon';
import { Avatar } from 'antd';
import { UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import Seperator from '../../components/Seperator/Seperator';
import OrgJobGroups from '../../components/alumni/OrgJobGroups';
import { IMilitaryCode, IOrgJobGroup } from '../../lib/entities';
import Loading from '../../assets/images/loading.gif';
import HeaderCompound from '../../compounds/HeaderCompound';
import { sendRequest } from '../../lib/api';
import NotFoundPage from '../NotFoundPage';
import './styles.css';

interface IFormData {
  first_name: string;
  last_name: string;
  nickname?: string | null;
  email: string;
  password: string;
  grad_level_id: number[] | null;
  degree_id: number[] | null;
  ibc_company_id: number | null;
  association_id: number[] | null;
  industry_id: number[] | null;
  hobby_id: number[] | null;
  city_id: number[] | null;
  country_id: number[] | null;
  job_title_id: number[] | null;
  military_branch_id: number | null;
  military_code_id: number | null;
  profile_pic?: string | null;
  profile_url?: string | null;
  grad_year: string | null;
  headline: string | null;
  youtube_video_url: string | null;
  type: 'OWN' | 'JOIN' | null;
  skills_id: number[] | null;
  org_job_groups: IOrgJobGroup[];
  athletes?: { name: string; positions: string[] | undefined } | null;
  romance?: string | null;
  sports_watches?: string[] | null;
  alumni_clubs?: string[] | null;
  linkedin_url?: string | null;
  sec_games: string[] | null;
}

const defaultForm: IFormData = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  grad_level_id: null,
  degree_id: null,
  ibc_company_id: null,
  association_id: null,
  industry_id: null,
  hobby_id: null,
  country_id: null,
  city_id: null,
  job_title_id: null,
  military_branch_id: null,
  military_code_id: null,
  grad_year: null,
  headline: null,
  youtube_video_url: null,
  type: null,
  skills_id: null,
  org_job_groups: [],
  sec_games: null,
};

const RegisterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const invite_token = new URLSearchParams(useLocation().search).get(
    'invite_token',
  );

  const utils = useSelector((state: RootState) => state.utils);

  const [formData, setFormData] = useState<IFormData>(defaultForm);
  const [loading, setLoading] = useState(false);
  const [schoolIDs, setSchoolIDs] = useState<number[]>([]);
  const [collegeIDs, setCollegeIDs] = useState<number[]>([]);
  const [pageActive, setPageActive] = useState<string | boolean>();
  const [mosCodes, setMosCodes] = useState<IMilitaryCode[]>([]);

  const loadMosData = async (military_branch_id: number) => {
    const res = await sendRequest(
      { military_branch_id },
      'GET',
      'api/military_codes',
    );
    if (res.status) {
      setMosCodes(res.data);
    }
  };
  const IsInvalid =
    formData.first_name.length <= 0 ||
    formData.last_name.length <= 0 ||
    formData.email.length <= 0 ||
    formData.password.length < 3;

  const updateForm = (key: keyof IFormData, value: any) => {
    if (key === 'military_branch_id') {
      loadMosData(value);
    }
    setFormData(prv => ({ ...prv, [key]: value }));
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formData.profile_pic) {
      alert(
        'You MUST add a profile photo to be a part of this OU Alumni community. Thanks!',
      );
      return;
    }

    setLoading(true);
    const res = await dispatch(
      authRegister({
        ...formData,
        school_id: [...schoolIDs, ...collegeIDs],
        invite_token,
      }),
    );
    setLoading(false);
    if (res === true) {
      history.replace(invite_token ? '/users' : '/verify');
      window.scrollTo({ top: 0 });
    } else {
      window.alert(res);
    }
  };
  const handleLoginClick = () => {
    history.push('/signin');
    const HeaderElement: HTMLElement | null = document.getElementById('header');
    HeaderElement?.scrollIntoView();
  };
  const handleFileChange = (type: 'profile_pic' | 'profile_url', file: any) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      updateForm(type, reader.result);
    };
    reader.readAsDataURL(file);
  };
  const accordionItems = [
    {
      key: 'ibc',
      label: 'Were yOU in the IBC program?:',
      children: (
        <div style={{ marginBottom: '30px' }}>
          <label>Which IBC company were you in?</label>
          <Select
            style={{ width: '100%' }}
            allowClear
            showSearch={true}
            value={formData.ibc_company_id?.toString()}
            options={utils.ibc_companies.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => updateForm('ibc_company_id', Number(v))}
          />
        </div>
      ),
    },
    {
      key: 'military',
      label: 'Affiliated with the USA Military?:',
      children: (
        <>
          <div style={{ marginBottom: '30px' }}>
            <label>Which branch do you most associate with?</label>
            <Select
              style={{ width: '100%' }}
              allowClear
              value={formData.military_branch_id?.toString()}
              options={utils.military_branches.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v => updateForm('military_branch_id', Number(v))}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>
          {mosCodes.length > 0 && (
            <div style={{ marginBottom: '30px' }}>
              <label>Which MOS code do you most associate with?</label>
              <Select
                style={{ width: '100%' }}
                allowClear
                value={formData.military_code_id?.toString()}
                options={mosCodes.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} - ${x.rank}`,
                }))}
                onChange={v => updateForm('military_code_id', Number(v))}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'athletes',
      label: 'Were you a student athlete?:',
      children: (
        <div style={{ marginBottom: '30px' }}>
          <label>Please select your sport and position:</label>
          <div style={{ margin: '10px 0' }}>
            <Select
              placeholder='Select your sport:'
              style={{ width: '100%' }}
              value={formData.athletes?.name}
              options={utils.athletes?.map(x => ({
                id: x.id,
                value: x.name,
                label: x.name,
              }))}
              onChange={t => updateForm('athletes', { name: t })}
            />
          </div>
          <Select
            placeholder='Select your position:'
            mode='multiple'
            style={{ width: '100%' }}
            options={utils.athletes
              ?.find(t => t.name === formData.athletes?.name)
              ?.positions?.map(x => ({ id: x, label: x, value: x }))}
            value={formData.athletes?.positions}
            onChange={vs =>
              updateForm('athletes', {
                name: formData.athletes?.name,
                positions: vs,
              })
            }
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      if (invite_token) {
        setLoading(true);
        const res = await sendRequest(
          {},
          'GET',
          `api/auth/invite-token/${invite_token}`,
        );
        setLoading(false);
        if (res.status) {
          setPageActive(true);
          setFormData(prv => ({ ...prv, email: res.data.email }));
        } else {
          setPageActive(res.data.message ?? 'Error');
          alert(res.data.message ?? 'Error');
        }
      } else {
        setPageActive(false);
      }
    })();
  }, [invite_token]);

  return pageActive === false ? (
    <NotFoundPage />
  ) : !!pageActive && typeof pageActive === 'string' ? (
    <div>{pageActive}</div>
  ) : (
    <>
      <HeaderCompound hideFeature={true} />
      <SignFormWrapper>
        <SignFormBase onSubmit={handleSubmit} method='POST'>
          <SignFormTitle>Become an OU Alumni Member Now:</SignFormTitle>
          <div style={{ marginBottom: '20px' }}>
            <p>
              yOUr privacy is OUr top priority! Rest assured, every detail you
              share on OUAlumni.org is guarded with the utmost care and respect,
              accessible only with your express permission. Currently we do not
              share any data with anyone and do not plan to. However, by joining
              the platform yOU are sharing yOUr email address and traits with
              other OUAlumni members. yOU do have the option to display yOUr
              email to OU alumni in specific traits that you choose on yOUr
              setting spage. Joining is absolutely free and OUAlumni.org is 100%
              run and owned by OU alumni.
            </p>
            <p>
              By registering below yOU'll be instantly matched with fellow
              Sooners, embracing the Boomer Sooner spirit with Sooner alumni
              from around the world. Remember, this platform is a Sooners-only
              zone, which is a unique space where OU alumni can connect, share,
              and grow, united by memories of Gaylord Family Oklahoma Memorial
              Stadium, walks through the South Oval, swirls at the Mont, and
              studying in the stacks.
            </p>
            <p>
              Please use OUAlumni.org as a way to build on the legacy of OUr
              beloved University of Oklahoma, fostering connections that go
              beyond the campus, to build the next generation of organizations
              and relationships. Join OU Alumni everywhere now and keep the
              Sooner spirit alive and thriving within OUr incredible alumni
              network! Boomer Sooner!
            </p>
            <p>Live on University.....</p>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Seperator></Seperator>
          </div>

          <SignFormInput
            type='text'
            placeholder='First Name:'
            value={formData.first_name}
            onChange={({ target }) => updateForm('first_name', target.value)}
          />
          <SignFormInput
            type='text'
            placeholder='Last Name:'
            value={formData.last_name}
            onChange={({ target }) => updateForm('last_name', target.value)}
          />
          <SignFormInput
            type='text'
            placeholder='Nickname:'
            value={formData.nickname ?? ''}
            onChange={({ target }) => updateForm('nickname', target.value)}
          />
          <SignFormInput
            type='email'
            placeholder='Email Address:'
            value={formData.email}
            disabled={!!invite_token}
            onChange={({ target }) => updateForm('email', target.value)}
          />
          <SignFormInput
            type='password'
            placeholder='Password:'
            autoComplete='off'
            value={formData.password}
            onChange={({ target }) => updateForm('password', target.value)}
          />

          <Seperator></Seperator>
          <div style={{ marginBottom: '30px' }}>
            <label>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                What year did you graduate?
              </div>
            </label>
            <Select
              placeholder='Select the year you graduated:'
              style={{ width: '100%' }}
              allowClear
              value={formData.grad_year}
              options={Array(55)
                .fill(null)
                .map((_, i) => (2023 - i).toString())
                .map(x => ({ id: x, label: x, value: x }))}
              onChange={v => updateForm('grad_year', v)}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>
              <div style={{ marginBottom: '20px' }}>
                What was your degree or major?
              </div>
            </label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.degree_id?.map(x => x.toString())}
              options={utils.degrees.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'degree_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <label>
              <div style={{ marginBottom: '20px' }}>
                Bachelors, Masters, or PhD?
              </div>
            </label>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              allowClear
              value={formData.grad_level_id?.map(x => x.toString())}
              options={utils.grad_levels.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'grad_level_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Seperator></Seperator>
          </div>

          <div className='white-color-panel'>
            <Collapse items={accordionItems} />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Seperator></Seperator>
          </div>
          <h2>Student Orgs:</h2>
          <div
            style={{
              marginTop: '30px',
              marginBottom: '30px',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <OrgJobGroups
              data={formData.org_job_groups}
              onChange={d => updateForm('org_job_groups', d)}
            />
          </div>
          <Seperator></Seperator>

          <div style={{ marginBottom: '30px' }}>
            <label>Are you single, engaged, or married?</label>
            <Select
              style={{ width: '100%' }}
              options={utils.romances?.map(x => ({
                id: x.id,
                value: x.name,
                label: x.name,
              }))}
              value={formData.romance}
              onChange={t => updateForm('romance', t)}
            />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <label>Which OU alumni clubs do you associate with:</label>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              options={utils.alumni_clubs?.map(x => ({
                id: x.id,
                value: x.name,
                label: x.name,
              }))}
              value={formData.alumni_clubs ?? undefined}
              onChange={vs => updateForm('alumni_clubs', vs)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <label>Which OU sports are you a fan of:</label>
            <Select
              style={{ width: '100%' }}
              mode='multiple'
              options={utils.sports_watches?.map(x => ({
                id: x.id,
                value: x.name,
                label: x.name,
              }))}
              value={formData.sports_watches ?? undefined}
              onChange={vs => updateForm('sports_watches', vs)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>What national associations are you in?</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.association_id?.map(x => x.toString())}
              options={utils.associations.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'association_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>Which industries are you a part of?</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.industry_id?.map(x => x.toString())}
              options={utils.industries.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'industry_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>What are your hobbies & interests?</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.hobby_id?.map(x => x.toString())}
              options={utils.hobbies.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'hobby_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>Which cities do you travel to often?</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.city_id?.map(x => x.toString())}
              options={utils.cities?.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'city_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>Which countries do you associate yourself with:</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.country_id?.map(x => x.toString())}
              options={utils.countries?.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'country_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>Which job titles do you have?</label>
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.job_title_id?.map(x => x.toString())}
              options={utils.job_titles.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v =>
                updateForm(
                  'job_title_id',
                  v.map(x => Number(x)),
                )
              }
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>

          <div style={{ marginBottom: '30px' }}>
            <label>Add Your Highschool:</label>
            <Select
              mode='multiple'
              showSearch={true}
              style={{ width: '100%' }}
              allowClear
              value={schoolIDs.map(x => x.toString())}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={utils.schools
                ?.filter(x => x.type === 'high_school')
                .map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
              onChange={v => setSchoolIDs(v.map(x => Number(x)))}
            />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <label>What other Colleges do you associate yourself with?</label>
            <Select
              mode='multiple'
              showSearch={true}
              style={{ width: '100%' }}
              allowClear
              value={collegeIDs.map(x => x.toString())}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={utils.schools
                ?.filter(x => x.type === 'college')
                .map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
              onChange={v => setCollegeIDs(v.map(x => Number(x)))}
            />
          </div>
          <div style={{ marginBottom: '30px' }}>
            <label>What SEC Game did you most played?</label>
            <Select
              showSearch={true}
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={formData.sec_games ?? []}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={utils.sec_games.map(x => ({
                id: x.id.toString(),
                value: x.name,
                label: x.name,
              }))}
              onChange={v => updateForm('sec_games', v)}
            />
          </div>
          <Seperator></Seperator>

          <div style={{ marginBottom: 20, marginTop: '20px' }}>
            <label>
              Please write a brief intro about yourself and your work:
            </label>
            <TextArea
              value={formData.headline ?? ''}
              onChange={e => updateForm('headline', e.target.value)}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <label>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                Add a Youtube video to your profile (simply, cut and paste a YT
                link below):
              </div>
            </label>
            <Input
              type='url'
              value={formData.youtube_video_url ?? ''}
              onChange={e => updateForm('youtube_video_url', e.target.value)}
            />
          </div>
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <label>
              <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                What is yOUr Linkedin website?
              </div>
            </label>
            <Input
              type='url'
              value={formData.linkedin_url ?? ''}
              onChange={e => updateForm('linkedin_url', e.target.value)}
            />
          </div>
          <Seperator></Seperator>
          <h2>OU Entrepreneurs and Co-Founders:</h2>
          <div
            style={{
              marginBottom: '30px',
              marginTop: '20px',
              color: 'white !important',
            }}
          >
            <div style={{ color: '#fff' }}>
              <Checkbox
                checked={formData.type === 'OWN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'OWN' : null)
                }
              >
                <span style={{ color: 'white' }}>
                  Check if you have a startup company and are looking for OU
                  alumni cofounders:
                </span>
              </Checkbox>
            </div>
          </div>
          <div style={{ marginBottom: '20px', color: 'white !important' }}>
            <Checkbox
              checked={formData.type === 'JOIN'}
              onChange={e =>
                updateForm('type', e.target.checked ? 'JOIN' : null)
              }
            >
              <span style={{ color: 'white' }}>
                Check if you are interested in joining an OU alumni startup as a
                cofounder:
              </span>
            </Checkbox>
          </div>
          {formData.type !== null && (
            <div style={{ marginBottom: '20px' }}>
              <label style={{ marginBottom: '20px' }}>
                {formData.type === 'OWN'
                  ? 'Select the roles / skills you are looking to fill: (please include something about your startup in your intro. Boomer Sooner!'
                  : 'Select the roles you might fit into well as a cofounder:'}
              </label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.skills_id?.map(x => x.toString())}
                options={utils.skills?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'skills_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
          )}

          <Seperator></Seperator>
          <div
            style={{
              marginBottom: 20,
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <div>
              <Avatar
                shape='circle'
                size={90}
                src={formData.profile_pic}
                icon={<UserOutlined />}
                style={{ background: '#3e3e3e' }}
              />
            </div>
            <div>
              <ImgCrop>
                <Upload
                  customRequest={(e: any) =>
                    handleFileChange('profile_pic', e.file)
                  }
                  fileList={[]}
                  listType='picture-card'
                  accept='image/x-png,image/gif,image/jpeg'
                >
                  <label htmlFor='upload-avatar' className='upload-button'>
                    <CameraIcon />
                    <span
                      className='upload-button__text'
                      style={{ color: 'white' }}
                    >
                      Add Profile Pic:
                      <div style={{ color: 'yellow' }}>(required!)</div>
                    </span>
                  </label>
                </Upload>
              </ImgCrop>
            </div>
          </div>
          <div
            style={{
              marginBottom: 40,
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <div>
              <Avatar
                shape='circle'
                size={100}
                src={formData.profile_url}
                icon={<AppstoreOutlined />}
                style={{ background: '#3e3e3e' }}
              />
            </div>
            <div>
              <ImgCrop>
                <Upload
                  customRequest={(e: any) =>
                    handleFileChange('profile_url', e.file)
                  }
                  fileList={[]}
                  listType='picture-card'
                  accept='image/x-png,image/gif,image/jpeg'
                >
                  <label htmlFor='upload-avatar' className='upload-button'>
                    <CameraIcon />
                    <span
                      className='upload-button__text'
                      style={{ color: 'white' }}
                    >
                      Background image:
                    </span>
                  </label>
                </Upload>
              </ImgCrop>
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Seperator></Seperator>
          </div>
          <div style={{ marginBottom: '20px', color: 'white !important' }}>
            <Checkbox defaultChecked={true}>
              <Link to='/privacy' style={{ color: 'white' }}>
                By checking this box you agree to our <u>privacy</u> policy and
                terms of use
              </Link>
            </Checkbox>
          </div>
          <Seperator></Seperator>

          {loading ? (
            <img src={Loading} alt='loading' />
          ) : (
            <SignFormButton disabled={IsInvalid} type='submit'>
              Join Now - Boomer Sooner!
            </SignFormButton>
          )}

          <Seperator></Seperator>
          <SignFormText>
            Already a Member?
            <SignFormLink onClick={handleLoginClick}>
              Sign in here!
            </SignFormLink>
          </SignFormText>
        </SignFormBase>
        <Seperator></Seperator>
        <iframe
          src='https://www.youtube.com/embed/4f-_SjZiIgM?si=oNNVt7mFoboKpcwk'
          title='YouTube Video'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{ width: '100%', height: '300px' }}
        ></iframe>

        <p>
          Watch this video by founder Jonathon Lunardi talking about the safety
          of your data and answers questions about becoming a member if you are
          an OU alumni.
        </p>
      </SignFormWrapper>
    </>
  );
};

export default RegisterPage;
