import React, { ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { NavLink } from '../components/Header/NavLink';
import { authLogout, authSetOnboardShow } from '../store/auth/auth.actions';
import { useHistory } from 'react-router-dom';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import Logo from '../components/Header/Logo';
import Navbar from '../components/Header/Navbar';
import BurgerBtn from '../components/Header/BurgerBtn';
import MobileMenu from '../components/Header/MobileMenu';
import MobileNavLink from '../components/Header/MobileNavLink';
import Banner from '../components/Header/Banner';
import { Button, Modal } from 'antd';

const FirstTimeModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <Modal
    title='Welcome !'
    onCancel={onClose}
    open={true}
    footer={
      <div className='mt-10'>
        <Button type='primary' onClick={onClose}>
          Close
        </Button>
      </div>
    }
    styles={{ header: { color: 'white' } }}
  >
    <div style={{ textAlign: 'center' }}>
      {' '}
      {/* Added style for center alignment */}
      <Logo /> {/* Render the Logo component */}
      <div>
        {
          'Thank you for joining OU Alumni, please make sure to invite friends and use the app! OUAlumni is built by OU Alumni for OU Alumni. Boomer Sooner! A your day be a Sooner Magical day... Live on University....'
        }
      </div>
    </div>
  </Modal>
);

const SecondTimeModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <Modal
    title='Welcome !'
    onCancel={onClose}
    open={true}
    footer={
      <div className='mt-10'>
        <Button type='primary' onClick={onClose}>
          Close
        </Button>
      </div>
    }
    styles={{ header: { color: 'white' } }}
  >
    <div>
      {
        'Welcome back, thank you so much for using OU Alumni! PLEASE make sure to fill out your profile and tell ONLY other OU grads about OUAlumni.org and invite them using the Invite feature. Dont Keep OUAlumni.org A Secret!'
      }
    </div>
  </Modal>
);

/*---> Component <---*/
const HeaderCompound = ({
  children,
  hideFeature,
  hideNav,
  size = 'lg',
}: PropsType) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const { token, profile, onboardShow } = useSelector(
    (state: RootState) => state.auth,
  );

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const handleClick = () => {
    setOpenMobileMenu(prev => !prev);
  };

  return (
    <>
      <HeaderWrapper wrapClass={size || 'lg'}>
        <Navbar hideNav={hideNav}>
          <Logo />
          {!token ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NavLink onClick={() => history.push('/signin')}>Sign In</NavLink>
            </div>
          ) : (
            <>
              {profile?.is_verified && (
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <NavLink onClick={() => history.push('/users')}>
                    Traits & Tribes
                  </NavLink>
                  <NavLink onClick={() => history.push('/history')}>
                    Members Historically
                  </NavLink>
                  <NavLink onClick={() => history.push('/browse')}>
                    Search
                  </NavLink>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                {profile?.is_verified && (
                  <>
                    <NavLink onClick={() => history.push('/profile')}>
                      Profile
                    </NavLink>
                    <NavLink onClick={() => history.push('/settings')}>
                      Settings
                    </NavLink>
                    <NavLink onClick={() => history.push('/invite')}>
                      Invites
                    </NavLink>
                  </>
                )}
                <NavLink
                  onClick={() => {
                    dispatch(authLogout());
                    history.replace('/signin');
                  }}
                >
                  Log Out
                </NavLink>
              </div>
            </>
          )}
          <BurgerBtn open={openMobileMenu} onClick={handleClick} />
        </Navbar>

        <MobileMenu open={openMobileMenu}>
          {!token ? (
            <>
              <MobileNavLink onClick={() => history.push('/signin')}>
                Sign In
              </MobileNavLink>
            </>
          ) : (
            <>
              {profile?.is_verified && (
                <>
                  <MobileNavLink onClick={() => history.push('/users')}>
                    Traits & Tribes
                  </MobileNavLink>
                  <MobileNavLink onClick={() => history.push('/history')}>
                    Members by History
                  </MobileNavLink>
                  <MobileNavLink onClick={() => history.push('/browse')}>
                    Search
                  </MobileNavLink>
                  <MobileNavLink onClick={() => history.push('/profile')}>
                    yOUr Profile
                  </MobileNavLink>
                  <MobileNavLink onClick={() => history.push('/settings')}>
                    Settings
                  </MobileNavLink>
                  <MobileNavLink onClick={() => history.push('/invite')}>
                    Invites
                  </MobileNavLink>
                </>
              )}
              <MobileNavLink
                onClick={() => {
                  dispatch(authLogout());
                  history.replace('/signin');
                }}
              >
                Log Out
              </MobileNavLink>
            </>
          )}
        </MobileMenu>

        {hideFeature ? <Banner /> : null}
        {/* {children} */}
      </HeaderWrapper>
      {!!token &&
        !!profile &&
        profile.logged_in_count < 2 &&
        onboardShow &&
        (profile.logged_in_count < 1 ? (
          <FirstTimeModal onClose={() => dispatch(authSetOnboardShow(false))} />
        ) : (
          <SecondTimeModal
            onClose={() => dispatch(authSetOnboardShow(false))}
          />
        ))}
    </>
  );
};

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
  hideFeature?: boolean;
  hideNav?: boolean;
  size?: 'lg' | 'md' | 'sm';
}

export default HeaderCompound;
